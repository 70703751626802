<template>
  <div>
    <div class="goods-wrapper">
      <div class="wrap">
        <!-- 轮播图 -->
        <div class="banner-wrap">
          <el-carousel indicator-position="outside" height="450px">
            <el-carousel-item v-for="(item,index) in goodsDetail.imgs" :key="index">
                <img class="cover"  :src="item" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- 图片放大镜 -->

        <!-- 商品详情 -->
        <div class="goods-main">
          <div class="goods-name">{{ goodsDetail.name }}</div>
          <div class="goods-desc">品牌 : {{ goodsDetail.pinpai }}</div>
          <div class="goods-desc">生产企业 : {{ goodsDetail.qiye }}</div>
          <div class="goods-desc">注册证号 : {{ goodsDetail.zhuce }}</div>
          <div class="sku-list">
            <div class="sku-wrap">
              <div class="title">选择规格</div>
              <div class="items">
                <div class="item active">
                  <!-- <img class="cover" src="http://8.136.157.116/upload/mainimg/5f0423dc-e914-4e44-b50d-ddd8bcd8ca4d.jpg" /> -->
                  <span class="name elip">{{ goodsDetail.xinghao ? goodsDetail.xinghao : '默认' }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="current-goods">
            <div class="goods-info">
              <div class="name">{{ goodsDetail.name }} {{ goodsDetail.xinghao ? goodsDetail.xinghao : '默认' }}</div>
            </div>
          </div>
          <div class="btn-wrap">
            <button class="btn-primary" @click="buy">立即购买</button>
            
            <button class="btn-gray" >{{ goodsDetail.qiye }}</button>
          </div>
        </div>
      </div>
      <div class="price-desc">
        <div class="wrap">
          <div class="title">商品详情</div>
          <img class="detailCover" v-for="(item,index) in goodsDetail.imgs" :key="index" :src="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    goodsId: Number,
  },
  data() {
    return {
      goodsDetail:{},
      id:''
    };
  },
  computed: {},
  created() {
    let list = JSON.parse(localStorage.getItem('goodsList'))
    let id = this.$route.query.id
    this.id = id
    for(let i = 0 ; i < list.length ; i ++){
      if(list[i].id == id){
        this.goodsDetail = list[i]
      }
    }
  },
  mounted() {},
  methods: {
    buy(){
      this.$router.push({
        path:'/car',
        query:{
          id:this.id
        }
      })
    }
  },
};
</script>

<style lang="scss">
.login-notic {
  background-color: #f5f5f5;
  width: 100%;
  height: 48px;
  line-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    color: #333;
  }

  .btn-login {
    color: #ff6700;
    margin-left: 25px;
    cursor: pointer;
  }

  .icon {
    color: #b5b5b5;
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
    cursor: pointer;
  }
}

.goods-wrapper {
  background-color: #ffffff;

  .wrap {
    width: 1226px;
    margin: 0 auto;
    display: flex;
    padding-top: 32px;
    .banner-wrap {
      width: 560px;
      height: 560px;
      position: relative;
      margin-right: 58px;

      .banner-swiper,
      .cover {
        width: 100%;
        height: 560px;
        cursor: pointer;
      }

      .btn {
        position: absolute;
        top: 50%;
        width: 41px;
        height: 69px;
        transform: translateY(-50%);
        cursor: pointer;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: rgba(0, 0, 0, 0.6);
        }

        .icon {
          font-size: 30px;
          color: #e0e0e0;
        }

        &.btn-left {
          left: 0;
        }

        &.btn-right {
          right: 0;
        }
      }

      /deep/ .uni-swiper-dots {
        bottom: 0;

        .uni-swiper-dot {
          display: inline-block;
          width: 50px;
          height: 2.5px;
          margin-right: 10px;
          background-color: #ccc;
          cursor: pointer;
          border-radius: 0;

          &.uni-swiper-dot-active {
            background-color: #a3a3a3;
          }
        }
      }
    }

    .magnifier {
      z-index: 1;
      width: 300px;
      height: 300px;
      position: fixed;
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid #999;
      background-color: #ffffff;
    }

    .goods-main {
      text-align: left;
      .goods-name {
        font-size: 24px;
        font-weight: normal;
        color: #212121;
        line-height: 1;
      }

      .goods-desc {
        color: #b0b0b0;
        padding-top: 8px;
      }

      .goods-price {
        font-size: 18px;
        line-height: 1;
        color: #ff6700;
        border-bottom: 1px solid #e0e0e0;
        padding: 20px 0;
      }

      .sku-list {
        margin-top: 40px;

        .sku-wrap {
          margin-bottom: 30px;

          .title {
            line-height: 1;
            font-size: 18px;
          }

          .items {
            display: flex;
            flex-wrap: wrap;
            width: 612px;
            overflow-x: hidden;

            .item {
              height: 42px;
              width: 298px;
              line-height: 42px;
              margin-top: 15px;
              margin-right: 6px;
              cursor: pointer;
              font-size: 16px;
              transition: all 0.2s ease;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #e0e0e0;

              &.active {
                border-color: #ff6700;

                .name {
                  color: #ff6700;
                }

                .price {
                  color: #757575;
                }
              }

              .name {
                display: inline-block;
                max-width: 170px;
                color: #333;
              }

              .thumb {
                width: 16px;
                height: 16px;
                margin-right: 10px;
              }

              .price {
                color: #b0b0b0;
                margin-left: 20px;
              }
            }
          }
        }
      }

      .current-goods {
        background: #f9f9fa;
        padding: 30px;
        margin-bottom: 30px;

        .goods-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          line-height: 30px;
          color: #616161;

          .price-wrap {
            display: flex;
            align-items: center;

            .original {
              text-decoration: line-through;
              margin-left: 5px;
            }
          }
        }

        .subtotal {
          color: #ff6700;
          font-size: 24px;
          padding-top: 20px;
          line-height: 30px;
        }
      }

      .btn-wrap {
        display: flex;
        align-items: center;

        .btn-primary {
          width: 300px;
          height: 57px;
          line-height: 54px;
          font-size: 16px;
          color: #ffffff;
          background-color: #ff6700;
          border: 1px solid #ff6700;
          text-align: center;
          margin: 0;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }

          &.disabled {
            cursor: not-allowed;
            background-color: #b0b0b0;
          }
        }

        .btn-gray {
          width: 280px;
          height: 57px;
          line-height: 54px;
          font-size: 16px;
          color: #ffffff;
          background-color: #b0b0b0;
          margin-left: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          border: 1px solid #b0b0b0;
          cursor: pointer;

          &:hover {
            background-color: #757575;
          }

          .icon {
            font-size: 22px;
            margin-right: 10px;

            &.animation {
              position: absolute;
              left: 39px;
            }
          }

          &.active {
            .icon {
              color: #e53935;

              &.animation {
                animation: zoom ease-out 1s;

                @keyframes zoom {
                  0% {
                    transform: scale(1) translate(0%, 0%);
                    opacity: 1;
                    filter: alpha(opacity=100) \9;
                  }

                  10% {
                    transform: scale(1) translate(-30%, -30%);
                    opacity: 0.7;
                    filter: alpha(opacity=70) \9;
                  }

                  100% {
                    transform: scale(3) translate(-0.5%, -0.5%);
                    opacity: 0;
                    filter: alpha(opacity=0) \9;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.price-desc {
  margin-top: 50px;
  background-color: #f5f5f5;

  .wrap {
    width: 1226px;
    margin: 0 auto;
    padding-bottom: 50px;
    display:flex;
    flex-wrap:wrap;
    .title {
      font-size: 22px;
      font-weight: normal;
      padding: 1em 0;
      display: block;
      width: 100%;
      text-align: left;
    }

    .detailCover {
      width: 70%;
      // height: 189px;
    }
  }
}
</style>
